import React from 'react'

import Layout from 'components/Layout'
import Sunset from 'components/Sunset'

export default () => {
  return (
    <Layout>
      <Sunset />
    </Layout>
  )
}