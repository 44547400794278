import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { rgba, stripUnit } from 'polished'

import Wrapper from 'components/Wrapper'
import Button from 'components/button/Button'
import Section from 'components/Section'
import { H1, H3, fontSizes, fontWeights } from 'styles/typography'
import { square, verticalPadding } from 'styles/global'
import { media, mediaDown } from 'styles/media'
import { white, black, primaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  background: linear-gradient(-90deg, #171717 0%, #000000 0%, #101013 6.95%, #19191D 75.57%, #282833 85.63%, #161617 93.8%, #000000 100%);
  text-align: center;
  color: ${rgba(white, 0.7)};
`

const Inner = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 460px;

  ${media.medium`
    max-width: 540px;
  `};

  ${media.large`
    max-width: 620px;
  `};
`

const Main = styled.div`
  position: relative;
  width: 100%;
`

const FigureWrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${spacing.xLarge};
  margin-bottom: -5vw;

  ${mediaDown.medium`
    padding: 0 ${spacing.xxLarge};
  `};

  ${media.medium`
    margin: ${spacing.xxLarge} 0;
  `};

  ${media.large`
    margin: ${spacing.xxxLarge} 0;
  `};

  ${media.xLarge`
    margin: 56px 0;
  `};
`

const Ring = styled.div`
  border: 18px solid ${white};
  box-shadow: 0 0 20px 6px ${rgba(white, 0.7)}, inset 0 0 15px 8px ${rgba(white, 0.5)};
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  ${square('170px')};

  ${media.xLarge`
    ${square('184px')};
  `};
`

const Heading = styled(H1)`
  color: ${white};
  font-weight: ${fontWeights.bold};
  margin-bottom: ${spacing.medium};

  ${media.medium`
    margin-bottom: ${spacing.large};
  `};
`

const Subheading = styled(H3)`
  color: ${white};
  margin-bottom: ${spacing.small};

  & + & {
    margin-top: ${spacing.large};

    ${media.large`
      margin-top: ${spacing.xLarge};
    `};
  }

  em {
    display: block;
  }
`

const LearnMore = styled(Link)`
  display: inline-block;
  text-decoration: underline;
  color: ${white};
`

const Disclaimer = styled.div`
  position: relative;
  z-index: 10;
  font-size: ${fontSizes.tiny};
  background-color: ${rgba(black, 0.35)};
  ${verticalPadding`1.5rem`};

  ${media.large`
    ${verticalPadding`2rem`};
  `};
`

const Device = styled.img`
  width: 70px;
  position: absolute;
  right: -110px;
  bottom: -80px;

  ${media.large`
    width: 80px;
    bottom: -90px;
  `};
`

const Sunset = () => (
  <Container>
    <Section>
      <Wrapper>
        <Inner>
          <Main>
            <Heading>glo is no longer available</Heading>
            <LearnMore to='/sunset-faq'>Learn why</LearnMore>
            <FigureWrap>
              <img
                src={require(`assets/images/home/vype-device-horizontal.png`)}
                className='u-hide-medium-up'
                alt=""
              />
              <Ring className='u-hide-medium-down' />
            </FigureWrap>
            <Subheading>Looking for an alternative?</Subheading>
            <Subheading><em>DISCOVER VYPE</em></Subheading>
            <p>Join Vype and get an ePod Starter Kit in exchange!</p>
            <Button
              to='/vype'
              theme='primary'
            >
              Get my ePod
            </Button>
            <Device
              src={require(`assets/images/home/vype-device-vertical.png`)}
              className='u-hide-medium-down'
              alt=""
            />
          </Main>
        </Inner>
      </Wrapper>
    </Section>
    <Disclaimer>
      <Wrapper>
        <p>*Offer valid until December 31st 2019 11:59PM EDT. Only for first-time registration to govype.ca. Existing govype.ca account holders are not eligible. Conditions and restrictions apply. Valid while quantities last. Not for Quebec and Nova Scotia residents. Cannot be combined with any other promotions. Limit of one product per account. A valid government-issued ID is required at delivery.</p>
      </Wrapper>
    </Disclaimer>
  </Container>
)

export default Sunset
